import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import BrotherGrid from '../components/brotherGrid';
import { brothers } from '../components/FilteredBros';
import './brothers.scss';

const Exec = props => {
  const mainRolesOrder = [
    'Eminent Commander',
    'Lieutenant Commander',
    'Marshal',
    'Chaplain',
    'Treasurer',
    'Recorder'
  ];

  const secondaryRolesOrder = [
    'Alumni Relations Chair',
    'Community Service Chair',
    'Houseman',
    'LEAD Chair',
    'Publicity Chair',
    'Scholarship Chair',
    'Social Chair',
    'Risk Chair',
    'Rush Chair',
    'Technology Chair',
    'Wellness Chair'
  ];

  const execBros = brothers.filter(
    bro => bro.active && bro.role !== null && bro.role.length !== 0
  );

  const mainExecBros = execBros.filter(bro => 
    bro.role.some(role => mainRolesOrder.includes(role))
  ).sort((a, b) => {
    const aRoleIndex = mainRolesOrder.findIndex(role => a.role.includes(role));
    const bRoleIndex = mainRolesOrder.findIndex(role => b.role.includes(role));
    return aRoleIndex - bRoleIndex;
  });

  const secondaryExecBros = execBros.filter(bro => 
    !bro.role.some(role => mainRolesOrder.includes(role))
  ).sort((a, b) => {
    const aRoleIndex = secondaryRolesOrder.findIndex(role => a.role.includes(role));
    const bRoleIndex = secondaryRolesOrder.findIndex(role => b.role.includes(role));
    return aRoleIndex - bRoleIndex;
  });

  return (
    <Layout>
      <Helmet>
        <title>Executive Team</title>
        <meta name="description" content="Brothers Page" />
      </Helmet>
      <section id="banner" className="major">
        <div className="inner">
          <h1 align="center">The Executive Team of ΣΝ</h1>
        </div>
      </section>
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            {/* Render main roles without filters and without year grouping */}
            <BrotherGrid 
              modal={false} 
              brothers={mainExecBros} 
              includeRole={true} 
              showFilters={false} 
              groupByYear={false} 
              sortAlphabetically={false} 
              customGroup={true} 
              showResizableFilter={false}  // Disable resizable filter slider
            />
            <div style={{ margin: '50px 0' }}></div> {/* Add space between sections */}
            {/* Render secondary roles without filters and without year grouping */}
            <BrotherGrid 
              modal={false} 
              brothers={secondaryExecBros} 
              includeRole={true} 
              showFilters={false} 
              groupByYear={false} 
              sortAlphabetically={false} 
              customGroup={true} 
              showResizableFilter={false}  // Disable resizable filter slider
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Exec;
